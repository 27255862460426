/* eslint-disable no-plusplus, no-restricted-syntax, guard-for-in */
import { CombinadOption } from './CombinadsContext';

export const type = {
  updateCombinadTitle: 'updateCombinadTitle',
  updateCombinadOption: 'updateCombinadOption',
  generateCombinadList: 'generateCombinadList',
  markAsFinished: 'markAsFinished',
};

function generateCombinations(title: string, combinadOptions: CombinadOption[]): CombinadOption[] {
  const combinadOptionsList = [];
  let id = 1;
  for (const option of combinadOptions) {
    for (const textOption of combinadOptions) {
      for (const ctaOption of combinadOptions) {
        const combinedOption = {
          name: `Ad#${id}_${title}_${option.imageDescription}`,
          id: id++,
          image64: option.image64,
          text: textOption.text,
          cta: ctaOption.cta,
        };
        combinadOptionsList.push(combinedOption);
      }
    }
  }
  return combinadOptionsList;
}

export const CombinadsReducer = (state: any, action: any) => {
  switch (action.type) {
    case type.updateCombinadTitle:
      return {
        ...state,
        combinadTitle: action.title,
      };
    case type.updateCombinadOption:
      return {
        ...state,
        combinadOptions: state.combinadOptions.map((combinadOption: CombinadOption) => {
          if (combinadOption.id === action.id) {
            return {
              ...combinadOption,
              ...action.combinadOption,
            };
          }
          return combinadOption;
        }),
      };
    case type.generateCombinadList:
      return {
        ...state,
        isGenerated: true,
        combinadsList: generateCombinations(state.combinadTitle, state.combinadOptions.map((combinadOption: CombinadOption) => ({
          imageDescription: combinadOption.imageDescription,
          image64: combinadOption.image64,
          text: combinadOption.text,
          cta: combinadOption.cta,
        }))),
      };
    case type.markAsFinished:
      return {
        ...state,
        combinadsList: state.combinadsList.map((combinad: CombinadOption) => {
          if (combinad.id === action.id) {
            return {
              ...combinad,
              finished: true,
            };
          }
          return combinad;
        }),
      };
    default:
      return state;
  }
};
