import { useEffect, useReducer, useState } from 'react';

import { CombinadContext, CombinadContextInterface, CombinadOption } from './CombinadsContext';
import { CombinadsReducer, type } from './CombinadsReducer';

import env from '@/utils/constants';

const INITIAL_STATE = {
  combinadTitle: '',
  combinadOptions: [{
    id: 1,
    text: env.VITE_APP_VERSION === 'local' ? 'Texto 1' : '',
    cta: env.VITE_APP_VERSION === 'local' ? 'CTA 1' : '',
  }, {
    id: 2,
    text: env.VITE_APP_VERSION === 'local' ? 'Texto 2' : '',
    cta: env.VITE_APP_VERSION === 'local' ? 'CTA 2' : '',
  }, {
    id: 3,
    text: env.VITE_APP_VERSION === 'local' ? 'Texto 3' : '',
    cta: env.VITE_APP_VERSION === 'local' ? 'CTA 3' : '',
  }],
  combinadsList: [],
  isGenerated: false,
};

type CombinadProviderProps = {
  children: React.ReactNode;
};

function CombinadProvider({ children }: CombinadProviderProps) {
  const [state, dispatch] = useReducer(CombinadsReducer, INITIAL_STATE);
  const [isReadyToGenerate, setIsReadyToGenerate] = useState(false);

  const handleUpdateCombinadOption = (id: number, combinadOption: Partial<CombinadOption>) => {
    dispatch({ type: type.updateCombinadOption, id, combinadOption });
  };

  const handleGenerateCombinadList = () => dispatch({ type: type.generateCombinadList });

  const handleMarkAsFinished = (id: number) => dispatch({ type: type.markAsFinished, id });

  useEffect(() => {
    // eslint-disable-next-line max-len
    setIsReadyToGenerate(!state.combinadTitle || !state.combinadOptions.every((combinadOption: CombinadOption) => combinadOption.text && combinadOption.image64 && combinadOption.cta && combinadOption.imageDescription));
  }, [state.combinadOptions, state.combinadTitle]);

  // eslint-disable-next-line react/jsx-no-constructed-context-values
  const value: CombinadContextInterface = {
    combinadTitle: state.combinadTitle,
    updateCombinadTitle: (title: string) => dispatch({ type: type.updateCombinadTitle, title }),
    combinadOptions: state.combinadOptions,
    getCombinadOption: (id: number) => state.combinadOptions.find((combinadOption: CombinadOption) => combinadOption.id === id) || { id },
    updateCombinadOption: handleUpdateCombinadOption,
    combinadsList: state.combinadsList,
    generateCombinadList: handleGenerateCombinadList,
    isGenerated: state.isGenerated,
    isReadyToGenerate,
    handleMarkAsFinished,
  };

  return (
    <CombinadContext.Provider value={value}>
      {children}
    </CombinadContext.Provider>
  );
}

export default CombinadProvider;
