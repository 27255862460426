import cx from 'classnames';
import { Button, Tooltip } from 'flowbite-react';
import { useState } from 'react';
import { HiOutlineClipboard } from 'react-icons/hi';
import { toast } from 'react-toastify';
import useCombinadsCtx from '../../hooks/useCombinadsCtx';

function CombinadsList() {
  const {
    isGenerated, generateCombinadList, combinadsList, isReadyToGenerate, handleMarkAsFinished,
  } = useCombinadsCtx();
  const [isLoading, setIsLoading] = useState(false);

  const handleGenerateCombinadList = () => {
    setIsLoading(true);
    generateCombinadList();
  };

  const handleCopyText = (type: string, text: string) => {
    navigator.clipboard.writeText(text);
    toast(`${type} copiado al portapapeles`, {
      type: 'success',
    });
  };

  return (
    <div className="max-w-6xl mx-auto my-5 p-6 xl:p-0">
      {!isGenerated && (
        <div className="flex justify-end">
          <Button
            color="dark"
            onClick={handleGenerateCombinadList}
            isProcessing={isLoading}
            disabled={isReadyToGenerate}
          >
            Generar combinaciones
          </Button>
        </div>
      )}
      {isGenerated && (
        <>
          <h2>Combinaciones Generadas</h2>
          {combinadsList.map((combinad) => (
            <div className="border border-gray-200 rounded-lg my-4" key={combinad.id}>
              <div className={cx('p-2 flex justify-between items-center', {
                'border-b': !combinad.finished,
              })}
              >
                <div className="flex gap-2 items-center">
                  <Tooltip content="Copiar">
                    <button
                      type="button"
                      className="hover:bg-gray-200 p-1 rounded-full"
                      onClick={() => handleCopyText('CTA', combinad.name ?? '')}
                    >
                      <HiOutlineClipboard className="text-xl" />
                    </button>
                  </Tooltip>
                  <h3 className={cx({ 'line-through': combinad.finished })}>
                    {combinad.name}
                  </h3>
                </div>
                {!combinad.finished && (
                  <Button size="xs" color="dark" onClick={() => handleMarkAsFinished(combinad.id)}>Marcar como terminado</Button>
                )}
              </div>
              {!combinad.finished && (
                <div className="p-2 grid md:grid-cols-3 gap-3">
                  <div>
                    <h3 className="mb-2">Imagen</h3>
                    <img
                      src={combinad.image64}
                      className="w-full h-52 object-contain object-top"
                      alt={combinad.imageBlob}
                    />
                  </div>
                  <div>
                    <div className="flex gap-2 items-center">
                      <h3>Texto</h3>
                      <Tooltip content="Copiar texto">
                        <button
                          type="button"
                          className="hover:bg-gray-200 p-1 rounded-full"
                          onClick={() => handleCopyText('Texto', combinad.text ?? '')}
                        >
                          <HiOutlineClipboard className="text-xl" />
                        </button>
                      </Tooltip>
                    </div>
                    {combinad.text}
                  </div>
                  <div>
                    <div className="flex gap-2 items-center">
                      <h3>CTA</h3>
                      <Tooltip content="Copiar CTA">
                        <button
                          type="button"
                          className="hover:bg-gray-200 p-1 rounded-full"
                          onClick={() => handleCopyText('CTA', combinad.cta ?? '')}
                        >
                          <HiOutlineClipboard className="text-xl" />
                        </button>
                      </Tooltip>
                    </div>
                    {combinad.cta}
                  </div>
                </div>
              )}
            </div>
          ))}
        </>
      )}
    </div>
  );
}

export default CombinadsList;
