import { backendSanitizedUrl } from '@/api/axios';

function LoginForm() {
  return (
    <div className="max-w-xs mx-auto mt-32 shadow-sm border border-gray-300 p-6 rounded-md flex flex-col gap-2">
      <div className="flex">
        <img
          alt="Celso García Logo"
          className="h-20 mb-5 object-contain"
          src="/logo.png"
        />
      </div>
      <a
        href={backendSanitizedUrl('/auth/google')}
        className="flex items-center gap-2 justify-center bg-gray-950 text-white p-2 rounded-full hover:opacity-80"
      >
        <img src="assets/google.svg" alt="google" />
        Continuar usando google
      </a>

      <small>
        Al continuar, acepto las
        {' '}
        <a className="text-blue-600 hover:underline" target="_blank" href="/legal/condiciones-del-servicio">Condiciones de uso</a>
        {' '}
        y la
        {' '}
        <a className="text-blue-600 hover:underline" target="_blank" href="/legal/politica-privacidad">Política de privacidad</a>
        .
      </small>
    </div>
  );
}

export default LoginForm;
