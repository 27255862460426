import CombinadListProvider from '@/context/combinads/CombinadsProvider';
import CombinadsList from '@/features/combinads/CombinadsList';
import CombinadsOptions from '@/features/combinads/CombinadsOptions';

function Combinads() {
  return (
    <CombinadListProvider>
      <CombinadsOptions />
      <CombinadsList />
    </CombinadListProvider>
  );
}

export default Combinads;
