import { Navbar as FlowbiteNavbar } from 'flowbite-react';

import UserAvatarDropdown from '@/features/UserAvatarDropdown';
import useAuth from '@/hooks/useAuth';

function Navbar() {
  const { user } = useAuth();

  if (!user) return null;

  return (
    <FlowbiteNavbar
      fluid
      rounded
    >
      <FlowbiteNavbar.Brand
        href="/"
      >
        <img
          alt="Celso García Logo"
          className="mr-3 h-14 object-contain"
          src="/logo.png"
        />
      </FlowbiteNavbar.Brand>
      <div className="flex gap-5 items-center">
        <UserAvatarDropdown />
      </div>
    </FlowbiteNavbar>
  );
}

export default Navbar;
