import { Label, TextInput } from 'flowbite-react';

import useCombinadsCtx from '@/hooks/useCombinadsCtx';

function CombinadsCta({ id }: { id: number }) {
  const { getCombinadOption, updateCombinadOption } = useCombinadsCtx();
  const cta = getCombinadOption(id).cta || '';

  const handleChange = (event: React.ChangeEvent<HTMLInputElement>) => {
    updateCombinadOption(id, { cta: event.target.value });
  };

  return (
    <div>
      <Label htmlFor={`cta-${id}`}>CTA</Label>
      <TextInput id={`cta-${id}`} value={cta} onChange={handleChange} />
    </div>
  );
}

export default CombinadsCta;
