import { Avatar, Dropdown } from 'flowbite-react';

import { backendSanitizedUrl } from '@/api/axios';
import useAuth from '@/hooks/useAuth';

function UserAvatarDropdown() {
  const { user } = useAuth();

  if (!user) return null;

  return (
    <Dropdown
      arrowIcon={false}
      inline
      label={(
        <span className="hover:opacity-95">
          <span className="sr-only">User menu</span>
          <Avatar
            size="sm"
            alt="avatar image"
            img={user.avatar}
            rounded
          />
        </span>
      )}

    >
      <Dropdown.Header>
        <span className="block text-sm">{`${user.name ?? ''}`}</span>
        <span className="block truncate text-sm font-medium">
          {user?.email}
        </span>
      </Dropdown.Header>
      <a className="block px-4 py-1 hover:bg-gray-100" href={backendSanitizedUrl('/auth/logout')}>Cerrar Sesión</a>
    </Dropdown>
  );
}

export default UserAvatarDropdown;
