import { createContext } from 'react';

export interface CombinadOption {
  id?: number;
  imageBlob?: string;
  image64?: string;
  imageDescription?: string;
  text?: string;
  cta?: string;
}

export interface CombinadOptionWithStatus extends CombinadOption {
  id: number;
  finished?: boolean;
  name?: string;
}

export interface CombinadContextInterface {
  combinadTitle: string;
  updateCombinadTitle: (title: string) => void;
  combinadOptions: CombinadOption[];
  getCombinadOption: (id: number) => CombinadOption;
  updateCombinadOption: (id: number, combinadOption: Partial<CombinadOption>) => void;
  combinadsList: CombinadOptionWithStatus[];
  generateCombinadList: () => void;
  isGenerated: boolean;
  isReadyToGenerate: boolean;
  handleMarkAsFinished: (id: number) => void;
}

export const combinadContextDefaultValue: CombinadContextInterface = {
  combinadTitle: '',
  updateCombinadTitle: () => { },
  combinadOptions: [],
  getCombinadOption: () => ({}),
  updateCombinadOption: () => { },
  combinadsList: [],
  generateCombinadList: () => { },
  isGenerated: false,
  isReadyToGenerate: false,
  handleMarkAsFinished: () => { },
};

export const CombinadContext = createContext<CombinadContextInterface>(combinadContextDefaultValue);
