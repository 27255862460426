import AppProvider from '@/context/AppProvider';
import AppRoutes from '@/router/AppRoutes';

function App() {
  return (
    <AppProvider>
      <AppRoutes />
    </AppProvider>
  );
}

export default App;
