import { Card } from 'flowbite-react';

import CombinadsCta from '@/features/combinads/CombinadsCta';
import CombinadsImageDropzone from '@/features/combinads/CombinadsImageDropzone';
import CombinadsTextarea from '@/features/combinads/CombinadsTextarea';

function CombinadsCard({ title, id }: { title: string, id: number }) {
  return (
    <Card>
      <h2>{title}</h2>
      <CombinadsImageDropzone id={id} />
      <CombinadsTextarea id={id} />
      <CombinadsCta id={id} />
    </Card>
  );
}

export default CombinadsCard;
