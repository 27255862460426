import { ToastContainer } from 'react-toastify';
import 'react-toastify/dist/ReactToastify.css';

import LoginForm from '@/components/LoginForm';
import Navbar from '@/components/Navbar';
import Combinads from '@/features/combinads/Combinads';
import useAuth from '@/hooks/useAuth';

function Home() {
  const { isAuthenticated } = useAuth();

  return (
    <>
      <Navbar />
      {!isAuthenticated ? <LoginForm /> : <Combinads />}
      <ToastContainer />
    </>
  );
}

export default Home;
