import { Label, TextInput } from 'flowbite-react';

import useCombinadsCtx from '@/hooks/useCombinadsCtx';

function CombinadTitle() {
  const { updateCombinadTitle, combinadTitle } = useCombinadsCtx();
  return (
    <div className="mt-4">
      <Label>Nombre de Campaña</Label>
      <TextInput value={combinadTitle} onChange={(e) => updateCombinadTitle(e.target.value)} />
    </div>
  );
}

export default CombinadTitle;
