import { useRoutes } from 'react-router-dom';

import HomePage from '@/pages/HomePage';
import PrivacyPolicy from '@/pages/PrivacyPolicy';
import TermsPage from '@/pages/TermsPage';

export function AppRoutes() {
  const element = useRoutes([
    {
      path: '/',
      element: <HomePage />,
    },
    {
      path: '/legal',
      children: [
        { path: 'politica-privacidad', element: <PrivacyPolicy /> },
        { path: 'condiciones-del-servicio', element: <TermsPage /> },
      ],
    },
    {
      path: '*',
      element: <HomePage />,
    },
  ]);
  return element;
}

export default AppRoutes;
