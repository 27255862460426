import CombinadTitle from '@/features/combinads/CombinadTitle';
import CombinadsCard from '@/features/combinads/CombinadsCard';
import useCombinadsCtx from '@/hooks/useCombinadsCtx';

function CombinadsOptions() {
  const { isGenerated } = useCombinadsCtx();

  if (isGenerated) return null;

  return (
    <div className="max-w-6xl mx-auto my-10 p-6 xl:p-0">
      <h1>Generador de Combinaciones</h1>
      <CombinadTitle />
      <div className="grid md:grid-cols-2 lg:grid-cols-3 gap-8 mt-5">
        <CombinadsCard title="Combinacion 1" id={1} />
        <CombinadsCard title="Combinacion 2" id={2} />
        <CombinadsCard title="Combinacion 3" id={3} />
      </div>
    </div>
  );
}

export default CombinadsOptions;
