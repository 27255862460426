/* eslint-disable react/jsx-props-no-spreading */
import { Button, TextInput } from 'flowbite-react';
import { useCallback, useEffect, useState } from 'react';
import { useDropzone } from 'react-dropzone';

import { BsFillTrash2Fill, BsUpload } from 'react-icons/bs';

import useCombinadsCtx from '@/hooks/useCombinadsCtx';

type AugmentedFile = File & { preview: string, base64: string, description: string };

type AugmentedFiles = AugmentedFile[];

function convertFileToBase64(file: File): Promise<string> {
  return new Promise((resolve, reject) => {
    const reader = new FileReader();
    reader.onload = () => {
      if (reader.result && typeof reader.result === 'string') {
        resolve(reader.result);
      } else {
        reject(new Error('Failed to read file as base64.'));
      }
    };
    reader.onerror = () => {
      reject(new Error('Failed to read file.'));
    };
    reader.readAsDataURL(file);
  });
}

function CombinadsImageDropzone({ id }: { id: number }) {
  const { updateCombinadOption } = useCombinadsCtx();
  const [files, setFiles] = useState<AugmentedFiles>([]);

  const onDrop = useCallback((acceptedFiles: any) => {
    setFiles(acceptedFiles.map((file: any) => Object.assign(file, {
      preview: URL.createObjectURL(file),
    })));
    acceptedFiles.map((file: File) => convertFileToBase64(file)
      .then((base64Data) => {
        updateCombinadOption(id, { image64: base64Data });
      })
      .catch((error) => {
        console.error(error);
      }));
  }, []);

  const { getRootProps, getInputProps } = useDropzone({
    maxFiles: 1,
    accept: {
      'image/*': [],
    },
    onDrop,
  });

  const thumbs = files.map((file) => (
    <img
      key={file.name}
      src={file.preview}
      className="w-full h-52 object-contain object-top"
      // Revoke data uri after image is loaded
      onLoad={() => { URL.revokeObjectURL(file.preview); }}
      alt="Preview"
    />
  ));

  const resetFiles = () => {
    setFiles([]);
  };

  useEffect(
    () =>
      // Make sure to revoke the data uris to avoid memory leaks, will run on unmount
      // eslint-disable-next-line implicit-arrow-linebreak
      () => files.forEach((file) => URL.revokeObjectURL(file.preview)),
    [],
  );

  useEffect(() => {
    updateCombinadOption(id, { imageBlob: files[0]?.preview });
  }, [files[0]?.preview]);

  return (
    <>
      {thumbs.length === 0 && (
        <div {...getRootProps({ className: 'dropzone' })}>
          <input {...getInputProps()} />
          <BsUpload className="mx-auto text-3xl mb-5" />
          <p>Arrasta y suelta la imagen aquí</p>
          <div className="my-2">
            <span>o</span>
          </div>
          <Button color="gray">Selecciona tu archivo</Button>
        </div>
      )}
      {thumbs.length > 0 && (
        <div className="w-full relative">
          <button
            type="button"
            onClick={resetFiles}
            className="p-2 bg-gray-100 rounded border border-gray-200 absolute top-2 right-2"
          >
            <BsFillTrash2Fill />
          </button>
          {thumbs}
          <div className="mt-4">
            <TextInput
              placeholder="Descripción de la imagen"
              onChange={(e) => updateCombinadOption(id, { imageDescription: e.target.value })}
              value={files[0]?.description}
            />
          </div>
        </div>
      )}
    </>
  );
}

export default CombinadsImageDropzone;
