import useCombinadsCtx from '@/hooks/useCombinadsCtx';

function CombinadsTextarea({ id }: { id: number }) {
  const { getCombinadOption, updateCombinadOption } = useCombinadsCtx();
  const text = getCombinadOption(id).text || '';

  const handleChange = (event: React.ChangeEvent<HTMLTextAreaElement>) => {
    updateCombinadOption(id, { text: event.target.value });
  };

  return (
    <div>
      <label htmlFor={`text-${id}`}>
        Texto
        <textarea id={`text-${id}`} value={text} onChange={handleChange} className="w-full h-20 border border-gray-200" />
      </label>
    </div>
  );
}

export default CombinadsTextarea;
