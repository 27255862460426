import Axios, { AxiosRequestConfig } from 'axios';

import env from '@/utils/constants';

export const axios = Axios.create({
  baseURL: env.VITE_API_URL,
  headers: {
    'Access-Control-Allow-Origin': '*',
  },
  withCredentials: true,
});

export const backendSanitizedUrl = (path: string): string => {
  const url = new URL(path, env.VITE_API_URL);
  return url.toString();
};

export const apiGet = async <T>(path: string, config?: AxiosRequestConfig): Promise<Api.Response<T>> => {
  const url = new URL(path, env.VITE_API_URL).toString();
  return axios.get(url, config).then((response) => ({
    status: response.status,
    data: response.data,
  } as Api.Response<T>));
};
